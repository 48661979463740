// extracted by mini-css-extract-plugin
export var button = "Vacancy-module--button--5596e";
export var container = "Vacancy-module--container--ad383";
export var content = "Vacancy-module--content--ab7a1";
export var left = "Vacancy-module--left--b3b08";
export var linkedInLink = "Vacancy-module--linkedInLink--9c8db";
export var list = "Vacancy-module--list--f7fc6";
export var main = "Vacancy-module--main--5a73a";
export var notification = "Vacancy-module--notification--d35a8";
export var notificationLink = "Vacancy-module--notificationLink--b4770";
export var right = "Vacancy-module--right--43307";
export var text = "Vacancy-module--text--80423";
export var textLink = "Vacancy-module--textLink--76532";
export var vacanciesLink = "Vacancy-module--vacanciesLink--fd73d";