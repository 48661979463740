import { graphql } from "gatsby"
import React from "react"
import Layout from "../../components/Layout/Layout"
import { Vacancy } from "../../components/Vacancy/Vacancy"
import SEO from "../../components/SEO"

interface Vacancy {
  title: string
  minimumExperienceYears: number
  skills: string[]
  jobTags: string[]
  requirements: string[]
  linkedInLink: string
  hrBypassLink: string
  aboutUs: { childMarkdownRemark: {html: string} }
}

interface VacancyQueryData {
  data: {
    contentfulVacancy: Vacancy
  }
}

const VacancyPage: React.FC<VacancyQueryData> = ({ data }) => {
  const { title, minimumExperienceYears, skills, jobTags, requirements, linkedInLink, hrBypassLink, aboutUs } = data.contentfulVacancy

  return (
    <Layout>
      <div>
        <SEO title={title} />
        <Vacancy
          title={title}
          minimumExperienceYears={minimumExperienceYears}
          skills={skills}
          jobTags={jobTags}
          requirements={requirements}
          linkedinLink={linkedInLink}
          hrBypassLink={hrBypassLink}
          aboutUs={aboutUs.childMarkdownRemark.html}
        />
      </div>
    </Layout>
  )
}
export default VacancyPage

export const query = graphql`
  query($slug: String) {
    contentfulVacancy(slug: { eq: $slug }) {
      title
      minimumExperienceYears
      skills
      jobTags
      requirements
      linkedInLink
      hrBypassLink
      aboutUs {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`