import { AnchorLink } from "gatsby-plugin-anchor-links"
import React from "react"
import { offers } from "../../data/offers"
import briefcase from "../../images/vacancyPage/briefcase.svg"
import spiralNotepad from "../../images/vacancyPage/spiralNotepad.svg"
import wavingHand from "../../images/vacancyPage/wavingHand.svg"
import BackButton from "../BackButton/BackButton"
import Button from "../Button"
import Notification from "../Notification"
import { InfoSection } from "./InfoSection/InfoSection"
import { KeyInfo } from "./KeyInfo/KeyInfo"
import LinkedInIcon from "./LinkedInIcon"
import { Title } from "./Title/Title"
import * as styles from "./Vacancy.module.scss"

type VacancyProps = {
  title: string
  minimumExperienceYears: number
  skills: string[]
  jobTags: string[]
  requirements: string[]
  linkedinLink: string
  hrBypassLink: string
  aboutUs: string
}

function getVacancyApplicationButton(hrBypassLink: string) {
  const buttonText = hrBypassLink ? "HR Screening Bypass" : "Join Us"
  const buttonLink = hrBypassLink ? hrBypassLink : "mailto:jobs@v4scale.com"
  return (
    <Button
      text={buttonText}
      color="violet"
      onClick={() => (window.location.href = buttonLink)}
      className={styles.button}
    />
  )
}

export const Vacancy: React.FC<VacancyProps> = ({
  title,
  minimumExperienceYears,
  skills,
  jobTags,
  requirements,
  linkedinLink,
  hrBypassLink,
  aboutUs,
}) => {
  const requirementItems = requirements.map(requirement => (
    <li className={styles.text} key={requirement}>
      {requirement}
    </li>
  ))
  const offersItems = offers.map(offer => (
    <li className={styles.text} key={offer}>
      {offer}
    </li>
  ))
  const links = [
    <BackButton />,
    <a href={linkedinLink} target="_blank">
      <div className={styles.linkedInLink}>
        <div className={styles.iconLink}>
          <LinkedInIcon />
        </div>
        <h5 className={styles.textLink}>LinkedIn</h5>
      </div>
    </a>,
  ]

  return (
    <div className={styles.main}>
      <section>
        <div className={styles.container}>
          <Title
            mainText={title}
            secondaryText="We are looking for"
            links={links}
          />
          <div className={styles.content}>
            <div className={styles.left}>
              <KeyInfo
                title="Experience"
                details={`${minimumExperienceYears}+ years`}
              ></KeyInfo>
              <KeyInfo title="Skills" details={skills.join(", ")}></KeyInfo>
              <KeyInfo title="Job" details={jobTags.join(", ")}></KeyInfo>
            </div>
            <div className={styles.right}>
              <InfoSection icon={wavingHand} title="About Us">
                <div
                  className={styles.text}
                  dangerouslySetInnerHTML={{ __html: aboutUs }}
                />
              </InfoSection>
              <InfoSection icon={spiralNotepad} title="Requirements">
                <ul className={styles.list}>{requirementItems}</ul>
              </InfoSection>
              <InfoSection icon={briefcase} title="We offer">
                <ul className={styles.list}>{offersItems}</ul>
              </InfoSection>
              <InfoSection>
                {getVacancyApplicationButton(hrBypassLink)}
              </InfoSection>
            </div>
          </div>
          <Notification
            className={styles.notification}
            link={
              <AnchorLink to="/#join-us">
                <h5 className={styles.notificationLink}>Why join our team?</h5>
              </AnchorLink>
            }
          />
        </div>
      </section>
    </div>
  )
}
